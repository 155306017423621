import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import BottomNav from "../common/BottomNav";
import { getUser, Logout } from "../../utils/UserController";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/account.css";
import { formatDate, formatMoney, getTabIndexByValue, showLoader } from "../../utils/Utils";
import {
  acGift,
  acLang,
  acNotify,
  acStatistics,
  av0,
  av1,
  av2,
  av3,
  av4,
  av5,
  av6,
  av7,
  av8,
  av9,
  av10,
  av11,
  av12,
  av13,
  av14,
  av15,
  av16,
  av17,
  av18,
  av19,
  av20,
  book,
  bulb,
  Cube,
  vip1,
  vip2,
  vip3,
  vip4,
  vip5,
  vip6,
  vip7,
  vip8,
  depositHistoryIcon,
  depositIcon,
  gameIcon,
  list,
  setting,
  support,
  tipLogo,
  tnxHistoryIcon,
  vipIcon,
  walletIcon,
  wdlHistoryIcon,
  withdraw,
} from "../../assets/media";
import Toast from "../components/Toast";

const Account = () => {
  const navigate = useNavigate();
  const tabsRef = useRef(null);
  const [activeTab, setActiveTab] = useState(2);
  const [user, setUser] = useState({});
  const [av, setAv] = useState(3);
  const [trigger, setTrigger] = useState(true);
  const [level, setLevel] = useState(0);

  const avatars = [
    av1,
    av2,
    av3,
    av4,
    av5,
    av6,
    av7,
    av8,
    av9,
    av10,
    av11,
    av12,
    av13,
    av14,
    av15,
    av16,
    av17,
    av18,
    av19,
    av20,
  ];

    const vips = [av0, vip1, vip2, vip3, vip4, vip5, vip6, vip7, vip8];

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000);

    // Hide after 3 seconds
  };

  const handleLogout = () => {
    const res = Logout();
    if (res) {
      $("#logout-confirm").removeClass("show");
      $(".overlay").addClass("d-none");
      $(".container").css("overflow", "");
      navigate("/login");
    }
  };
  const handleConfirm = (open) => {
    if (open) {
      $(".overlay").removeClass("d-none");
      $(".container").css("overflow", "hidden");
      $("#logout-confirm").addClass("show");
    } else {
      $(".overlay").addClass("d-none");
      $(".container").css("overflow", "");
      $("#logout-confirm").removeClass("show");
    }
  };

  const handleCopy = (txt) => {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Copy successful`
        );
      })
      .catch((err) => {
        showToast("Failed to copy");
      });
  };
  const handleRefresh = () => {
    setTrigger((prev) => !prev);
    setTimeout(() => {
      showToast(
        `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Refresh successfully`
      );
    }, 500);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (tabsRef.current) {
        const tabContainer = tabsRef.current;
        const containerWidth = tabContainer.offsetWidth;

        // Check which tab should be active
        let newActiveTab = activeTab;
        Array.from(tabContainer.children).forEach((tab, index) => {
          const tabRect = tab.getBoundingClientRect();
          const containerRect = tabContainer.getBoundingClientRect();

          const overlapWidth =
            Math.min(tabRect.right, containerRect.right) -
            Math.max(tabRect.left, containerRect.left);

          const tabVisiblePercentage = (overlapWidth / containerWidth) * 100;

          if (tabVisiblePercentage >= 60) {
            newActiveTab = index;
          }
        });

        if (newActiveTab !== activeTab) {
          setActiveTab(newActiveTab);
        }

        // Smoothly scroll to the new active tab
        const activeTabElement = tabContainer.children[newActiveTab];
        const tabElementWidth = activeTabElement.offsetWidth;
        const scrollPosition =
          activeTabElement.offsetLeft -
          (containerWidth / 2 - tabElementWidth / 2);
        tabContainer.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    };

    handleScroll();

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeTab]);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const res = await getUser();
        setUser(res.data.data);
        setAv(res.data.data.avatar);
        setLevel(getTabIndexByValue(res.data.data.exp));
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, [trigger]);

  return (
    <>
      {/* <Header /> */}
      <div className="ac-banner"></div>
      <div className="user-details-container">
        <div className="user-details">
          <Link to={"/account/ChangeAvatar"}>
            <img src={avatars[av]} alt="" />
          </Link>
          <div className="d-flex flex-col" style={{ padding: "5px 0px" }}>
            <div className="ac-name">
              <span>{user?.name}</span>
              <img src={vips[level]} alt="" />
            </div>
            <div className="ac-uid" onClick={() => handleCopy(user?.uid)}>
              <span>UID</span>l<span>{user?.uid}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15px"
                height="15px"
                viewBox="0 0 256 256"
              >
                <path
                  fill="#fff"
                  d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8m-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z"
                ></path>
              </svg>
            </div>
            <div className="ac-login">
              <span>Last Login :</span>
              <span>{formatDate("Y-m-d h:M:S A", user?.last_login)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="ac-balance-card">
        <span className="tb-txt">Total balance</span>
        <span className="tb-amt" onClick={handleRefresh}>
          {formatMoney(user?.money)}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15px"
            height="15px"
            viewBox="0 0 15 15"
          >
            <path
              fill="#768096"
              fillRule="evenodd"
              d="M13.293 3L11.146.854l.708-.708l3 3a.5.5 0 0 1 0 .708l-3 3l-.708-.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5V8H0V6.5A3.5 3.5 0 0 1 3.5 3zM15 7v1.5a3.5 3.5 0 0 1-3.5 3.5H1.707l2.147 2.146l-.708.708l-3-3a.5.5 0 0 1 0-.707l3-3l.708.707L1.707 11H11.5A2.5 2.5 0 0 0 14 8.5V7z"
              clipRule="evenodd"
            ></path>
          </svg>
        </span>
        <div className="ac-btns">
          <Link to={"/wallet"}>
            <div className="d-flex flex-col align-center justify-center">
              <img src={walletIcon} alt="" />
              <span>Wallet</span>
            </div>
          </Link>
          <Link to={"/wallet/Recharge"}>
            <div className="d-flex flex-col align-center justify-center">
              <img src={depositIcon} alt="" />
              <span>Deposit</span>
            </div>
          </Link>
          <Link to={"/wallet/Withdraw"}>
            <div className="d-flex flex-col align-center justify-center">
              <img src={withdraw} alt="" />
              <span>Withdraw</span>
            </div>
          </Link>
          <Link to={"/vip"}>
            <div className="d-flex flex-col align-center justify-center">
              <img src={vipIcon} alt="" />
              <span>VIP</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="history-grid">
        <Link to={"/account/BetHistory"}>
          <div className="history-btn">
            <img src={gameIcon} alt="" />
            <div className="d-flex flex-col">
              <span className="hb-txt">Game history</span>
              <span className="hb-des">My game history</span>
            </div>
          </div>
        </Link>
        <Link to={"/wallet/TransactionHistory"}>
          <div className="history-btn">
            <img src={tnxHistoryIcon} alt="" />
            <div className="d-flex flex-col">
              <span className="hb-txt">Transaction</span>
              <span className="hb-des">My transaction history</span>
            </div>
          </div>
        </Link>
        <Link to={"/wallet/RechargeHistory"}>
          <div className="history-btn">
            <img src={depositHistoryIcon} alt="" />
            <div className="d-flex flex-col">
              <span className="hb-txt">Deposit</span>
              <span className="hb-des">My deposit history</span>
            </div>
          </div>
        </Link>
        <Link to={"/wallet/WithdrawHistory"}>
          <div className="history-btn">
            <img src={wdlHistoryIcon} alt="" />
            <div className="d-flex flex-col">
              <span className="hb-txt">Withdrawal</span>
              <span className="hb-des">My withdraw history</span>
            </div>
          </div>
        </Link>
      </div>
      <div className="ac-link-container">
        <Link to={"/home/Messages"}>
          <div className="ac-link">
            <div className="d-flex align-center">
              <img src={acNotify} alt="" />
              <span>Notification</span>
            </div>
            <div className="d-flex align-center">
              <span className="n-box" style={{ color: "#fff" }}>
                1+
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </div>
        </Link>
        <Link to={"/activity/RedeemGift"}>
          <div className="ac-link">
            <div className="d-flex align-center">
              <img src={acGift} alt="" />
              <span>Gifts</span>
            </div>
            <div className="d-flex align-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </div>
        </Link>

        {user?.role === "admin" ? (
          <a href={"https://ctapi.site/admin/dashboard?id=" + user?.uid}>
            <div className="ac-link">
              <div className="d-flex align-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 512 512"
                  style={{ marginRight: "5px" }}
                >
                  <path
                    fill="var(--main-color)"
                    d="m448 362.7l-117.3-21.3C320 320 320 310.7 320 298.7c10.7-10.7 32-21.3 32-32c10.7-32 10.7-53.3 10.7-53.3c5.5-8 21.3-21.3 21.3-42.7s-21.3-42.7-21.3-53.3C362.7 32 319.2 0 256 0c-60.5 0-106.7 32-106.7 117.3c0 10.7-21.3 32-21.3 53.3s15.2 35.4 21.3 42.7c0 0 0 21.3 10.7 53.3c0 10.7 21.3 21.3 32 32c0 10.7 0 21.3-10.7 42.7L64 362.7C21.3 373.3 0 448 0 512h512c0-64-21.3-138.7-64-149.3"
                  ></path>
                </svg>
                <span>Welcome Mr. {user?.name}</span>
              </div>
              <div className="d-flex align-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#1e2637"
                    d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                  />
                </svg>
              </div>
            </div>
          </a>
        ) : (
          ""
        )}
        <Link to={"/"}>
          <div className="ac-link">
            <div className="d-flex align-center">
              <img src={acStatistics} alt="" />
              <span>Game statistics</span>
            </div>
            <div className="d-flex align-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </div>
        </Link>
        <Link to={"/"}>
          <div className="ac-link last">
            <div className="d-flex align-center">
              <img src={acLang} alt="" />
              <span>Language</span>
            </div>
            <div className="d-flex align-center">
              <span>English</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </div>
        </Link>
      </div>

      <div className="setting-container">
        <span
          style={{ gridColumn: "1 / -1", fontSize: "1.1rem", color: "#fff" }}
        >
          Service center
        </span>
        <Link to={"/Setting"}>
          <div className="setting-box ">
            <img src={setting} alt="" />
            <span>Settings</span>
          </div>
        </Link>
        <Link to={"/account/Feedback"}>
          <div className="setting-box ">
            <img src={list} alt="" />
            <span>Feedback</span>
          </div>
        </Link>
        <Link to={"/main/Notification"}>
          <div className="setting-box ">
            <img src={bulb} alt="" />
            <span>Notification</span>
          </div>
        </Link>
        <Link to={"/promotion/CustomerSupport"}>
          <div className="setting-box ">
            <img src={support} alt="" />
            <span>24/7 Customer service</span>
          </div>
        </Link>
        <Link to={"/Guide"}>
          <div className="setting-box ">
            <img src={book} alt="" />
            <span>Beginner's Guide</span>
          </div>
        </Link>
        <Link to={"/account/About"}>
          <div className="setting-box ">
            <img src={Cube} alt="" />
            <span>About us</span>
          </div>
        </Link>
      </div>

      <div className="logoutbtn" onClick={() => handleConfirm(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="var(--main-color)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M7.023 5.5a9 9 0 1 0 9.953 0M12 2v8"
            color="var(--main-color)"
          ></path>
        </svg>
        <span>Log Out</span>
      </div>
      <div className="size-box-100"></div>
      <div className="logout-confirm" id="logout-confirm">
        <img src={tipLogo} alt="" />
        <span className="warn-txt">Do you want to log out?</span>
        <div className="confirm-btn" onClick={handleLogout}>
          Confirm
        </div>
        <div className="cancel-btn" onClick={() => handleConfirm(false)}>
          Cancel
        </div>
      </div>
      <BottomNav />
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Account;
