import React from 'react'
import CommonHeader from '../../common/CommonHeader';

const Guide = () => {
  return (
    <>
      <CommonHeader
        path="/account"
        heading="Beginner's Guide"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />

      <div className="guide-container-content">
        <p>
          <b>
            1. How to Register
            <br />- Fill Your Phone Number
            <br />- Set Your Own Password ( 8 min with big-small letters and
            number ）<br />- Confirm The Password
            <br />- Invitation code
            <br />- Click I Have Read And Agree
            <br />- Click Register
            <br />
            <br />
          </b>
          <b>
            <br />
          </b>
        </p>
        <p>
          <b>
            2. How to betting on WINGO game
            <br />
          </b>
          <b>
            - Enter Wingo game
            <br />
          </b>
          <b>
            - Select the duration of the game (1 minute, 3 minutes, 5 minutes,
            10 minutes)
            <br />
          </b>
          <b>
            Green : if the result shows 1,3,7,9
            <br />
          </b>
          <b>
            Red : if the result shows 2,4,6,8
            <br />
          </b>
          <b>
            Violet : if the result shows 0 or 5<br />
          </b>
          <b>
            Small : if the result shows 0,1,2,3,4
            <br />
          </b>
          <b>
            Big : if the result shows 5,6,7,8,9
            <br />
          </b>
          <b>
            This company not allowed to place Illegal betting
            <br />
          </b>
          <b>
            Exp ：Betting (Big and small together) or (Red and Green together)
            or (bet more than 7 number) in the same time.
            <br />
            <br />
          </b>
          <br />
        </p>
        <p>
          <b>
            3.How to recharge
            <br />
          </b>
          <b>
            Click Wallet Icon, Click The Recharge Button, and we have 5 ways to
            make a recharge (UPIPAY-APP,UPIPAY,UPI-TRANSFER, USDT, TRX )<br />
            <br />
          </b>
          <br />
        </p>
        <p>
          <b>
            4. How to Withdraw
            <br />
          </b>
          <b>
            Click Wallet menu, click Withdraw Button.
            <br />
          </b>
          <b>
            - Enter withdraw amount
            <br />
          </b>
          <b>
            - Make Sure Your Total Bet already 0<br />
          </b>
          <b>
            - Select Your Bank Account Or Add Your Bank Account
            <br />
          </b>
          <b>
            - Input Amount You Want To Withdraw
            <br />
          </b>
          <b>
            - Input Your Login Password
            <br />
            <br />
          </b>
          <br />
        </p>
        <p>
          <b>
            5. Betting History
            <br />
          </b>
          <b>
            When The Betting Complete You Can Click My History to see Your Bet
            Record, You Can Check The Chart Trend for helping you decide the
            next bet you do also game history showing the previous result.
            <br />
            <br />
          </b>
          <br />
        </p>
        <p>
          <b>
            6. Transaction
            <br />
          </b>
          <b>
            You can check all the transaction or activity you do inside the
            account on transaction, that you can find on account menu
            <br />
            <br />
          </b>
          <br />
        </p>
        <p>
          <b>
            7.Promotion
            <br />
          </b>
          <b>
            - If you have a downline or referral member use your own link to
            register and if they make a recharge you can claim a reward. The
            agent will get a minimum commission of 0.7 % (level 1) and 0.75%
            (level 2) from each transaction that is done by the referral (Added
            every day at 01:00 AM), each game having different percentage you
            can check it on Promotion menu to check and sport not count on
            rebate.
            <br />
          </b>
          <b>
            - You Can Click The Sharing Invitation Poster To See The Barcode
            <br />
            <br />
          </b>
          <br />
        </p>
        <p>
          <b>
            8. Change Password
            <br />
          </b>
          <b>
            Follow the guide below to change password
            <br />
          </b>
          <b>
            - Login to CLUBTATA account
            <br />
          </b>
          <b>
            - Press Account
            <br />
          </b>
          <b>
            - Press Setting menu
            <br />
          </b>
          <b>
            - Press Login Password.
            <br />
          </b>
          <b>
            - Fill your login password.
            <br />
          </b>
          <b>
            - Filll new login password
            <br />
          </b>
          <b>
            - Re-fill new login password
            <br />
          </b>
          <b>
            - Press Save Changes
            <br />
            <br />
          </b>
          <br />
        </p>
        <p>
          <b>
            9. Binding Bank Account
            <br />
          </b>
          <b>
            - Login to CLUBTATA account
            <br />
          </b>
          <b>
            - Press Wallet menu
            <br />
          </b>
          <b>
            - Press Withdraw menu
            <br />
          </b>
          <b>
            - Press Add Bank
            <br />
          </b>
          <b>
            - Fill all the collumn
            <br />
          </b>
          <b>
            - Press Save
            <br />
            <br />
          </b>
          <br />
        </p>
        <p>
          <b>
            10. Forgot Password
            <br />
          </b>
          <b>
            - Go to CLUBTATA website
            <br />
          </b>
          <b>
            - Press Account menu
            <br />
          </b>
          <b>
            - Press Forgot Password
            <br />
          </b>
          <b>
            - Fill phone number you register
            <br />
          </b>
          <b>
            - Press Send to receive OTP
            <br />
          </b>
          <b>
            - Fill the OTP
            <br />
          </b>
          <b>
            - Fill new password
            <br />
          </b>
          <b>
            - Refill new password
            <br />
          </b>
          <b>
            - Press i have read and agree privacy agreement
            <br />
          </b>
          <b>
            - Press Reset
            <br />
            <br />
          </b>
          <br />
        </p>
        <p>
          <b>
            11. App Download
            <br />
          </b>
          <b>
            To download the Apps you can go to Home page on the right top side
            you will see the download button
            <br />
            <br />
          </b>
          <br />
        </p>
        <p>
          <b>
            12. About
            <br />
          </b>
          <b>
            Press about for more details regarding Privacy Policy and Risk
            Disclosure Agreement
            <br />
            <br />
            <br />
          </b>
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>
            13. Gift
            <br />
          </b>
          <b>
            - Login to CLUBTATA account
            <br />
          </b>
          <b>
            - Press Account
            <br />
          </b>
          <b>
            - Press gift menu
            <br />
          </b>
          <b>
            - Fill the gift codes
            <br />
          </b>
          <b>
            - Press receive
            <br />
          </b>
          <b>Notes: To get gift codes you can ask with your superior agent</b>
        </p>
        <p>
          <b>
            <br />
          </b>
        </p>
      </div>
    </>
  );
}

export default Guide