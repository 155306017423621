import React, { useEffect, useRef, useState } from "react";
import CommonHeader from "../../common/CommonHeader";
import { useLocation } from "react-router-dom";
import Toast from "../Toast";
import $ from "jquery";
import GradientButton from "../GradientButton";

const Invite = () => {
  const location = useLocation();
  const { state } = location;

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const handleInvite = () => {
    const link =
      process.env.REACT_APP_BASE_URL +
      "/#/register?InvitationCode=" +
      state;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Copy successful`
        );
      })
      .catch((err) => {
        showToast("Failed to copy");
      });
  };

  const tabsRef = useRef(null);
  const [activeTab, setActiveTab] = useState(1);
  const tabs = [
    {
      img: "",
    },
    { img: "" },
    { img: "" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (tabsRef.current) {
        const tabContainer = tabsRef.current;
        const containerWidth = tabContainer.offsetWidth;

        let newActiveTab = activeTab;
        Array.from(tabContainer.children).forEach((tab, index) => {
          const tabRect = tab.getBoundingClientRect();
          const containerRect = tabContainer.getBoundingClientRect();

          const overlapWidth =
            Math.min(tabRect.right, containerRect.right) -
            Math.max(tabRect.left, containerRect.left);

          const tabVisiblePercentage = (overlapWidth / containerWidth) * 100;

          if (tabVisiblePercentage >= 60) {
            newActiveTab = index;
          }
        });
        if (newActiveTab !== activeTab) {
          setActiveTab(newActiveTab);
        }

        const activeTabElement = tabContainer.children[newActiveTab];
        const tabElementWidth = activeTabElement.offsetWidth;
        const scrollPosition =
          activeTabElement.offsetLeft -
          (containerWidth / 2 - tabElementWidth / 2);
        tabContainer.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    };

    handleScroll();
    $(".vip-content-container").fadeOut();
    $(".vip-content-container").fadeIn(300);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeTab]);

  return (
    <>
      <CommonHeader
        path="/promotion"
        heading="Invite"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div
        style={{
          color: "var(--lightText)",
          width: "93%",
          margin: "auto",
          padding: "20px 5px",
        }}
      >
        Please swipe left - right to choose your favorite poster
      </div>
      <div className="share-container">
        <div className="share-tabs-container" ref={tabsRef}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`share-tab ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
            ></div>
          ))}
        </div>
      </div>
      <div
        style={{
          color: "var(--mainText)",
          width: "93%",
          margin: "auto",
          padding: "20px 5px",
          fontSize: "1rem",
          fontWeight: "bold",
        }}
      >
        Invite friends Income 10 billion Commission
      </div>
      <div className="downloadlinkbtn" onClick={null}>
        <GradientButton
          bg={"var(--main_gradient-color)"}
          text={"INVITATION LINK"}
          color={"#fff"}
          fw={"bold"}
        />
      </div>
      <div className="copylinkbtn" onClick={handleInvite}>
        <span>Copy invitation link</span>
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Invite;
